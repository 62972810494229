<template>
  <div class="">
    <p v-if="false">{{ userData }}</p>

    <div class="row no-gutters">
      <div class="col-xl-10 offset-xl-1">
        <h3 class="text-uppercase mb-3">
          <strong>{{ $t("privacy.title") }} </strong>
        </h3>

        <div class="">


          <p><small><strong>{{ $t("step.privacy_1") }}</strong></small></p>

          <div class="mb-3">
            <u><a :href="pdfLink" download="InformativaPrivacyPaziente">{{$t("step.downloadPrivacy")}}</a></u>
          </div>

          <p><small>{{ $t("step.privacy_2") }}</small></p>  

          <!-- Inizio radio -->
          
          <div>            
            
            <b-form-group>

              <!-- 1 -->

              <p><small><br><strong>{{ $t("step.cons_1") }}</strong></small></p>

              <b-form-radio-group
                  id="radio-privacy"
                  v-model="userData.cons_1"
                  name="privacy"
                  disabled="true"
              >
                <b-form-radio value=true>{{
                    $t("step.consenso_si")
                  }}
                </b-form-radio>
                <b-form-radio v-if="false" value=false>{{
                    $t("step.consenso_no")
                  }}
                </b-form-radio>
              </b-form-radio-group>

              <!-- 2 -->

              <p><small><br><strong>{{ $t("step.cons_2") }}</strong></small></p>

              <b-form-radio-group
                  id="radio-privacy-2"
                  v-model="userData.cons_2"
                  name="privacy-2"
              >
                <b-form-radio value=true>{{
                    $t("step.consenso_si")
                  }}
                </b-form-radio>
                <b-form-radio value=false>{{
                    $t("step.consenso_no")
                  }}
                </b-form-radio>
              </b-form-radio-group>

              <!-- 3 -->

              <p><small><br><strong>{{ $t("step.cons_3") }}</strong></small></p>

              <b-form-radio-group
                  id="radio-privacy-3"
                  v-model="userData.cons_3"
                  name="privacy-3"
              >
                <b-form-radio value=true>{{
                    $t("step.consenso_si")
                  }}
                </b-form-radio>
                <b-form-radio value=false>{{
                    $t("step.consenso_no")
                  }}
                </b-form-radio>
              </b-form-radio-group>

              <!-- 4 -->

              <p><small><br><strong>{{ $t("step.cons_4") }}</strong></small></p>

              <b-form-radio-group
                  id="radio-privacy-4"
                  v-model="userData.cons_4"
                  name="privacy-4"
              >
                <b-form-radio value=true>{{
                    $t("step.consenso_si")
                  }}
                </b-form-radio>
                <b-form-radio value=false>{{
                    $t("step.consenso_no")
                  }}
                </b-form-radio>
              </b-form-radio-group>

              <!-- 5 -->

              <p><small><br><strong>{{ $t("step.cons_5") }}</strong></small></p>

              <b-form-radio-group
                  id="radio-privacy-5"
                  v-model="userData.cons_5"
                  name="privacy-5"
              >
                <b-form-radio value=true>{{
                    $t("step.consenso_si")
                  }}
                </b-form-radio>
                <b-form-radio value=false>{{
                    $t("step.consenso_no")
                  }}
                </b-form-radio>
              </b-form-radio-group>

              <!-- 6 -->

              <div v-if="false">
                <p><small><br><strong>{{ $t("step.cons_6") }}</strong></small></p>

                <b-form-radio-group
                    id="radio-privacy-6"
                    v-model="userData.cons_6"
                    name="privacy-6"
                >
                  <b-form-radio value=true>{{
                      $t("step.consenso_si")
                    }}
                  </b-form-radio>
                  <b-form-radio value=false>{{
                      $t("step.consenso_no")
                    }}
                  </b-form-radio>
                </b-form-radio-group>
              </div>

              <!-- 7 -->

              <div v-if="false">
                <p><small><br><strong>{{ $t("step.cons_7") }}</strong></small></p>

                <b-form-radio-group
                    id="radio-privacy-7"
                    v-model="userData.cons_7"
                    name="privacy-7"
                >
                  <b-form-radio value=true>{{
                      $t("step.consenso_si")
                    }}
                  </b-form-radio>
                  <b-form-radio value=false>{{
                      $t("step.consenso_no")
                    }}
                  </b-form-radio>
                </b-form-radio-group>
              </div>

            </b-form-group>

            <b-alert v-model="setMsg" :variant="setMsgType">
            {{ (this.setMsgResult ? $t('privacy.alert_ok') : $t('privacy.alert_ko')) }}
            </b-alert>      

            <div class="text-center mt-3">
              <b-button
                  @click="showConfirm"
                  variant="primary"
                  size="md"
              >{{ $t("generic.update") }}
              </b-button
              >
            </div>            
            
          </div>
        </div>
      </div>

    </div>

    <app-confirm :showModal="showModal" @confirm="save()" @cancel="abort()"></app-confirm>

  </div>
</template>

<script>

import apiAxios from "@/libs/apiAxios";
import AppConfirm from './AppConfirm.vue';

export default {
  components: { AppConfirm },
  name: "AppPrivacy",
  props: ["userData"],

  data() {
    return {
      pdfLink: require("@/assets/docs/InformativaPrivacyPazienti.pdf"),
      setMsg: false,
      setMsgType: null,
      setMsgResult: null,
      showModal: false
    };
  },

  computed: {},

  methods: {
    showConfirm() {
      this.showModal = true;
      this.setMsg = false;      
    },

    abort() {
      this.showModal = false;
    },

    save() {
      this.showModal = false;      

      const data={
        consensi: [this.userData.cons_1,
                this.userData.cons_2,
                this.userData.cons_3,
                this.userData.cons_4,
                this.userData.cons_5,
                this.userData.cons_6,
                this.userData.cons_7,
                false,
                false,
                false]
      }

      apiAxios.post("/user/setConsensi", data)
      .then(response=>{
        const resp=response.data;
        if (resp.SetConsensiResult)
        {
          this.setMsg = true;
          this.setMsgType = 'success';
          this.setMsgResult = true;
        }
        else {
          this.setMsg = true;
          this.setMsgType = 'danger';
          this.setMsgResult = false;
        }
      })   
    }
  },
};
</script>
