<template>
  <div class="row no-gutters h-100" v-if="false">
    <div class="col-xxl-8 offset-xxl-1">
      <h3 class="text-uppercase">
        <strong>{{ $t("familyInformation.title") }} </strong>
      </h3>
      <p>{{ $t("familyInformation.message_1") }}</p>
      <div class="row no-gutters">
        <div class="col-xl-10 mt-2 align-self-start">
          <div class="m-card m-card--name">
            <span class="icon-user1"></span>
            <p>Maria Rosa Rossi</p>
            <a href=""><span class="icon-delete"></span></a>
          </div>
        </div>
        <div class="col-xl-10 mt-2 align-self-start">
          <div class="m-card m-card--name">
            <span class="icon-user1"></span>
            <p>Mario Rosa Rossi</p>
            <a href=""><span class="icon-delete"></span></a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-8 offset-xl-1 align-self-end">
      <h4 class="text-uppercase">
        <strong>{{ $t("familyInformation.message_2") }} </strong>
      </h4>
      <a class="m-card__mewuser text-light" href="/aggiungiFamiliare">
        <span class="m-card__mewuser--icon icon-user1"></span>
        <span class="m-card__mewuser--name">{{
          $t("familyInformation.action")
        }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppFamilyInformation",
  props: ["userData"],

  data() {
    return {};
  },
  computed: {},

  methods: {},
};
</script>
