<template>
  <div class="row no-gutters">
    <div class="col-xl-8 offset-xl-1">
      <h3 class="text-uppercase">
        <strong>{{ $t("billingInformation.title") }} </strong>
      </h3>
    </div>
    <div v-if="userData!=null" class="col-xl-10 offset-xl-1">
      <div class="m-card m-card--container mt-3">
        <div class="m-card__text ">
          <p>
            <strong>{{ $t("billingInformation.message_1") }}</strong>
          </p>
        </div>
        <div class="m-card__text ">
          <span class="icon-phone text-primary"></span>
          <p>
            {{ $t("billingInformation.message_2") }}
          </p>
        </div>
        <div class="m-card__text ">
          <span class="icon-time text-primary"></span>
          <p>
            {{ $t("billingInformation.message_3") }}
          </p>
        </div>
      </div>

      <div class="m-card m-card--editdata mt-3">
        <div class="m-card__form">
          <b-form-group
            v-if="userData.nazione!=null"
            id="nation"
            :label="this.$t('registration.nation_billing')"
            label-for="nation-input"
            class="m-input"            
          >
            <b-form-select @change="changeNation()"
              id="nation-input"
              v-model="userData.nazione.codice"
              type="text"
              :options="this.nations"
              disabled="true"
            >
            </b-form-select>
          </b-form-group>
          <b-form-group v-if="isNazionale"
            id="province"
            :label="this.$t('registration.country_billing')"
            label-for="province-input"
            class="m-input"
          >
            <b-form-select @change="onChange()"
              id="province-input"
              v-model="userData.citta.prov"
              type="text"
              :options="this.provincie"
              placeholder=""
              disabled="true"
            ></b-form-select>
          </b-form-group>

          <b-form-group v-if="isNazionale"
            id="city"
            :label="this.$t('registration.city')"
            label-for="city-input"
            class="m-input"
          >
            <b-form-select
              id="city-input"
              v-model="userData.citta.codice"
              type="text"
              placeholder=""
              :options="comuni"
              disabled="true"
            ></b-form-select>
          </b-form-group>

          <b-form-group
            id="ind"
            :label="this.$t('registration.address')"
            label-for="ind-input"
            class="m-input"
          >
            <b-form-input
              id="ind-input"
              v-model="userData.indirizzo"
              type="text"
              disabled="true"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="cap"
            :label="this.$t('registration.cap')"
            label-for="cap-input"
            class="m-input"
          >
            <b-form-input
              id="cap-input"
              v-model="userData.cap"
              type="number"
              disabled="true"
            ></b-form-input>
          </b-form-group>
        </div>
        <!--<div class="m-card__action  m-card__action--col">
          <b-button @click="sendDetail()" class=" mb-1" variant="primary" size="md">{{
            $t("generic.edit")
          }}</b-button>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import apiAxios from "@/libs/apiAxios";

export default {
  name: "AppBillingInformation",
  props: [],

  created() {
    this.fillNations();
    this.fillProvincie();
    this.getBilling();
  },

  data() {
    return {
      newData: {},
      myData:null,
      myNations:[],
      myProvincie:[],
      myComuni:[],
      invoice:null,
      isNazionale:true,
      inCitta:{codice:null,prov:null},

    };
  },
  computed: {
    userData(){
      return this.myData;
    },
    comuni(){

      if (!this.isNazionale)
        return [];
      let tmp=[];
      for (let i=0;i<this.myComuni.length;i++)
      {
        const obj=this.myComuni[i];
        tmp.push({value:obj.codice,text:obj.comune});

      }
      return tmp;
    },
    provincie(){
      let tmp=[];
      if (this.isNazionale) {
        for (let i = 0; i < this.myProvincie.length; i++) {
          const obj = this.myProvincie[i];
          tmp.push({value: obj.prov, text: obj.provincia});

        }
      }
      else {
        return [];
      }
      return tmp;
    },
    nations(){
      let tmp=[];
      for (let i=0;i<this.myNations.length;i++)
      {
        const obj=this.myNations[i];
        tmp.push({value:obj.codice,text:obj.denominazione})
      }
      return tmp;
    }

  },

  methods: {

    getCache(name)
    {
      const key="cache@"+name.replace(/\//g,'@');

      const cache=sessionStorage.getItem(key)

      if (cache==null)
        return null;

      const val=JSON.parse(cache);

      return val;
    },

    setCache(name,value)
    {
      const key="cache@"+name.replace(/\//g,'@');

      sessionStorage.setItem(key,JSON.stringify(value))

    },
    async fillProvincie()
    {
      let res=this.getCache("lookup/province");
      if (res!=null)
      {
        this.myProvincie=res;
        return;
      }
      let tmp=[];
      await(apiAxios.get("lookup/province"))
      .then(response=>{
        tmp=response.data.ProvinceResult;
        this.setCache("lookup/province",tmp);
      });
      this.myProvincie=tmp;
    },
    async fillCity(prov)
    {
      let res=this.getCache("lookup/comuni/"+prov);
      if (res!=null)
      {
        this.myComuni=res;
        return;
      }
      let tmp=[];
      await(apiAxios.get("lookup/comuni/"+prov))
          .then(response=>{
             tmp=response.data.ComuniResult;
             this.setCache("lookup/comuni/"+prov,tmp)

          });
      this.myComuni=tmp;
    },

    async doFillNations()
    {
      let res=this.getCache("lookup/nazioni");
      if (res!=null)
      {
        this.myNations=res;
        return;
      }
      apiAxios.get("lookup/nazioni")
      .then(response => {
        this.myNations=response.data.getNazioniResult;
        this.setCache("lookup/nazioni",this.myNations)
      })
    },
    fillNations() {
      if (this.myNations.length<2)
      {
        this.doFillNations();
      }
    },
    async getBilling()
    {
      let tmp={};
      await apiAxios.get("/user/detail")
        .then(response => {

          const detail=response.data.UserDetailResult;
          tmp=detail;
        });


      this.isNazionale=false;
      if (tmp.citta!=null) {
        this.myProvincie = [tmp.citta];
        this.fillCity(tmp.citta.prov);
      }
      else {
        tmp.citta={prov:null,codice:null};
      }
      this.myData=tmp;
      this.changeNation();

    },

    changeNation()
    {
      if (this.userData.nazione.codice==999100)
      {
        this.isNazionale=true;
      }
      else {
        this.isNazionale=false;
      }
    },
    onChange(){
      if(this.userData.citta!=null)
      this.fillCity(this.userData.citta.prov);
    },
    sendDetail()
    {
      const data={
        nazione:this.userData.nazione,
        citta:this.userData.citta,
        cap:this.userData.cap,
        indirizzo:this.userData.indirizzo
      };
      apiAxios.post("user/billing",data)
      .then(()=>{

      })

    }
  },
};
</script>
