<template>
  <div  class="row no-gutters">

    <div class="col-xl-8 offset-xl-1">
      <h3 class="text-uppercase">
        <strong>{{ $t("personalData.title") }}</strong>
      </h3>
    </div>
    <div class="col-xl-10 offset-xl-1">
      <div class="m-card m-card--container mt-3">
        <div class="m-card__text ">
          <p>
            <strong>{{ $t("personalData.message_1") }}</strong>
          </p>
        </div>
        <div class="m-card__text ">
          <span class="icon-phone text-primary"></span>
          <p>
            {{ $t("personalData.message_2") }}
          </p>
        </div>
        <div class="m-card__text ">
          <span class="icon-time text-primary"></span>
          <p>
            {{ $t("personalData.message_3") }}
          </p>
        </div>
      </div>

      <div class="m-card m-card--container m-card--dark mt-3">
        <div class="mt-2">
          <div class="m-card--info">
            <p>{{ $t("registration.name") }}:</p>
            <p>
              <strong>  {{ userData.nome }}</strong>
            </p>
          </div>
          <div class="m-card--info">
            <p>{{ $t("registration.surname") }}:</p>
            <p>
              <strong>{{ userData.cognome }}</strong>
            </p>
          </div>
          <div class="m-card--info" v-if="!straniero">
            <p>{{ $t("registration.cf") }}:</p>
            <p>
              <strong>{{ userData.codiceFiscale }}</strong>
            </p>
          </div>
          <div class="m-card--info" v-if="userData.codiceSTP!=null">
            <p>{{ $t("registration.stp") }}:</p>
            <p>
              <strong>{{ userData.codiceSTP }}</strong>
            </p>
          </div>
          <div class="m-card--info" v-if="userData.codiceTeams!=null">
            <p>{{ $t("registration.team") }}:</p>
            <p>
              <strong>{{ userData.codiceTeams }}</strong>
            </p>
          </div>
          <div class="m-card--info">
            <p>{{ $t("registration.birthdate") }}:</p>
            <p>
              <strong>{{ userData.dataNascita | moment("DD/MM/YYYY") }}</strong>
            </p>
          </div>
          <div class="m-card--info">
            <p>{{ $t("registration.gender") }}:</p>
            <p>
              <strong>{{userData.sesso  }}</strong>
            </p>
          </div>
          <div class="m-card--info">
            <p>{{ $t("registration.nation_born") }}:</p>
            <p>
              <strong>{{  userData.nazionalita!=null?userData.nazionalita.denominazione:"" }}</strong>
            </p>
          </div>
          <div class="m-card--info" v-if="!straniero">
            <p>{{ $t("registration.country_born") }}:</p>
            <p>
              <strong>{{ userData.comuneNascita==null?"":userData.comuneNascita.prov}}</strong>
            </p>
          </div>
          <div class="m-card--info"  v-if="!straniero">
            <p>{{ $t("registration.city_born") }}:</p>
            <p>
              <strong>{{ userData.comuneNascita==null?"":userData.comuneNascita.comune }}</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "AppPersonalData",
  props: ["userData"],
  data() {
    return {

    };
  },
  computed: {
    straniero(){
      console.log(this.userData);
      if (this.userData.nazionalita==null)
        return false;
      console.log(this.userData.nazionalita.codice);
      return this.userData.nazionalita.codice!==999100?true:false;
    }

  },

  methods: {},
};
</script>
