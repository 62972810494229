<template>

  <b-modal
    id="confirmModal"
    centered
    v-model="showModal"
    modal-class="o-modal"
    content-class="o-modal__content"
    header-class="o-modal__header"
    dialog-class="o-modal__dialog"
    body-class="o-modal__body o-modal__body--response "
    :cancel-disabled="true"
    :hide-footer="true"
    :hide-header-close="true"
    :hide-header="true"
    size="lg"
    >

      <div class="mt-5">
        {{ $t("confirmModal.txt") }}
      </div>          
      <b-button @click="confirm()" variant="primary" class="text-uppercase mt-3">{{ $t("confirmModal.ok") }}</b-button>
      <b-button @click="cancel()" variant="outline-primary" class="text-uppercase mt-3">{{ $t("confirmModal.ko") }}</b-button>
  
  </b-modal>

</template>

<script>

export default {
  components: {},
  name: "AppConfirm",
  props: ["showModal"],

  data() {
    return {};
  },

  methods: {
    cancel() {
      this.$emit('cancel', {});
    },
    confirm() {
      this.$emit('confirm', {});
    } 
  },
};
</script>