<template>
  <div class="row no-gutters" v-if="payload!=null">
    <div class="col-xl-8 offset-xl-1" >
      <h3 class="text-uppercase">
        <strong>{{ $t("loginCredetials.title") }} </strong>
      </h3>
      <div class="mt-3">
        <b-form-group
          id="username"
          :label="this.$t('registration.username')"
          label-for="username-input"
          class="m-input"
        >
          <b-form-input
            id="username-input"
            v-model="payload.username"
            type="text"
            disabled
          ></b-form-input>
        </b-form-group>
      </div>
    </div>
    <div class="col-xl-10 offset-xl-1">


      <div class="m-card m-card--editdata mt-3">
        <div class="m-card__form">
          <b-form-group
              id="password"
              :label="this.$t('registration.doChangepassword')"
              label-for="email-input"
              class="m-input"
          >
            <div class="m-card__action">
              <b-button @click="$router.push('/changePassword')" class=" mb-1" variant="primary" size="md">
                {{$t("generic.changePassword")}}
              </b-button>
            </div>
          </b-form-group>
          <!-- <b-form-group
            id="reEmail"
            :label="this.$t('registration.email_repeat')"
            label-for="reEmail-input"
            class="m-input m-input__icon"
          >
            <div class="icon-email mr-3"></div>
            <b-form-input
              id="reEmail-input"
              v-model="newData.reEmail"
              type="email"
              :state="this.$isEmail(newData.reEmail)"
              :placeholder="this.$t('registration.email_repeat_placeholder')"
              required
            ></b-form-input>
          </b-form-group> -->
        </div>

      </div>

      <div class="m-card m-card--editdata mt-3">
        <div class="m-card__form">
          <b-form-group
            id="email"
            :label="this.$t('registration.email')"
            label-for="email-input"
            class="m-input m-input__icon"
          >
            <div class="icon-email mr-3"></div>
            <b-form-input
              id="email-input"
              v-model="userData.email"
              type="email"
              :state="this.$isEmail(userData.email)"
              :placeholder="this.$t('registration.email_placeholder')"
              required
            ></b-form-input>
          </b-form-group>
          <!-- <b-form-group
            id="reEmail"
            :label="this.$t('registration.email_repeat')"
            label-for="reEmail-input"
            class="m-input m-input__icon"
          >
            <div class="icon-email mr-3"></div>
            <b-form-input
              id="reEmail-input"
              v-model="newData.reEmail"
              type="email"
              :state="this.$isEmail(newData.reEmail)"
              :placeholder="this.$t('registration.email_repeat_placeholder')"
              required
            ></b-form-input>
          </b-form-group> -->
        </div>
        <div class="m-card__action">
          <b-button @click="updateEmail()" class=" mb-1" variant="primary" size="md">
            {{$t("generic.edit")}}
          </b-button>
          <app-auth-panel v-if="showAuthEmail" action="changeMail" :value="userData.email" media="sms" :message="this.$t('dettagli.sms_email_message')" @success="emailAuth()" @fail="emailFail()"/>
        </div>
      </div>


      <div class="m-card m-card--editdata mt-3">
        <div class="m-card__form">
          <b-form-group
            id="tel"
            :label="this.$t('registration.phone')"
            label-for="tel-input"
            class="m-input m-input__icon"
          >
            <div class="icon-phone-mex mr-3"></div>
            <b-form-input
              id="tel-input"
              v-model="userData.cellulare"
              type="tel"
              :state="this.$isPhone(userData.cellulare)"
              :placeholder="this.$t('registration.phone_placeholder')"
              required
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="m-card__action">
          <b-button @click="updateSms()" class=" mb-1" variant="primary" size="md">{{
            $t("generic.edit")
          }}</b-button>
          <app-auth-panel v-if="showAuthSms" action="changePhone" :value="userData.cellulare" media="email" :message="this.$t('dettagli.email_sms_message')" @success="smsAuth()" @fail="smsFail()"/>
        </div>
      </div>

      <div v-if="false">
        <h3 class="text-uppercase mt-3">
          <strong>{{ $t("loginCredetials.title2") }} </strong>
        </h3>      

        <div v-if="!strong" class="m-bunner-strong">
          <div class="m-bunner-strong__container">
            <div class="m-bunner-strong__line"></div>
            <span class="icon-lock"></span>
            <div class="m-bunner-strong__line"></div>
          </div>
          <p class="text-center">
            {{ $t("registration.credentials.message") }}
            <b-button class=" mb-1" variant="warning" size="sm" @click="reloadUser()">Ricarica</b-button>
          </p>
        </div>

        <div v-if="strong" class="m-card m-card--editdata mt-3">
          <div class="m-card__form">
            <b-form-group
                id="codiceMSP"
                :label="this.$t('registration.credentials.codiceMSP_placeholder')"
                label-for="codiceMSP-input"
                class="m-input"
            >
              <div v-if="this.binded" >

                <p class="text-success">
                  <strong>{{ $t("registration.credentials.validated") }}</strong>
                </p>
              </div>
              <b-form-input v-if="!this.binded"
                  id="codiceMSP-input"
                  v-model="codiceMSP"
                  :placeholder="this.$t('registration.credentials.codiceMSP_placeholder')"
                  required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="m-card__action" v-if="!this.binded">
            <b-button @click="updateMSP()" class=" mb-1" variant="primary" size="md">{{
                $t("generic.invia")
              }}</b-button>
          </div>
        </div>
        <div  v-if="validated" class="m-card m-card--editdata mt-3">
          <div class="m-card__form">
            <b-form-group
                id="codAuth"
                :label="this.$t('registration.credentials.codAuth_placeholder')"
                label-for="codauth-input"
                class="m-input"
            >
              <div v-if="this.authenticated" >

                <label class="text-success ">
                  <strong>{{ $t("registration.credentials.authenticated") }}</strong>
                </label>
              </div>
              <b-form-input v-if="this.authenticable"
                            id="codauth-input"
                            v-model="codAuth"
                            :placeholder="this.$t('registration.credentials.codAuth_placeholder')"
                            required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="m-card__action" v-if="this.authenticable">
            <b-button @click="updateCodAuth()" class=" mb-1" variant="primary" size="md">{{
                $t("generic.invia")
              }}</b-button>
          </div>
        </div>
        <div  v-if="false" class="m-card m-card--editdata mt-3">
          <div class="m-card__form">
            <b-form-group
                id="pin"
                :label="this.$t('registration.credentials.pin')"
                label-for="pin-input"
                class="m-input m-input__icon"
            >
              <div class="icon-phone-check mr-3"></div>
              <div v-if="this.pinned" >

                <p class="text-success">
                  <strong>{{ $t("registration.credentials.pinned") }}</strong>
                </p>
              </div>
              <b-form-input v-if="!this.pinned"
                            id="pin-input"
                            v-model="pin"
                            :placeholder="this.$t('registration.pin_placeholder')"
                            required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="m-card__action" v-if="!this.pinned">
            <b-button @click="updatePin()" class=" mb-1" variant="primary" size="md">{{
                $t("generic.invia")
              }}</b-button>
          </div>
          <app-auth-panel v-if="showAuthPin" action="setPin" :value="pin" media="sms" :message="this.$t('dettagli.pin_sms_message')" @success="pinAuth()" @fail="pinFail()"/>
        </div>
      </div>
    </div>




  </div>
</template>

<script>
import AppAuthPanel from "@/components/organisms/AppAuthPanel";
import apiAxios from "@/libs/apiAxios";
import store from "@/store";
export default {
  name: "AppLoginCredetials",
  components: {AppAuthPanel},
  props: ["userData"],

  create(){

  },
  data() {
    return {
      codiceMSP:'',
      codAuth:'',
      pin:'',
      showAuthEmail:false,
      showAuthSms:false,
      showAuthPin:false,
      newData: {},
    };
  },
  computed: {
    payload: function (){
      const user=this.$store.getters["dateUser/UserData"];
      console.log(user);
      return user;
    },
    strong: function () {
      if (this.payload==null)
        return false;
      if (!('role' in this.payload))
        return false;
      return this.payload.role===3;
    },
    binded: function () {
      if (this.payload==null)
        return false;
      if ('bind' in this.payload)
        return this.payload.bind==1;
      return false;
    },
    validated: function () {
      if (this.payload==null)
        return false;
      if ('validation' in this.payload)
        return this.payload.validated>0;
      return false;
    },
    authenticated: function () {
      if (this.payload==null)
        return false;
      if ('authenticated' in this.payload)
        return this.payload.authenticated;
      return false;
    },
    authenticable: function () {
      if (this.payload==null)
        return false;
      if ('authenticable' in this.payload)
        return this.payload.authenticable;
      return false;
    },
    pinned: function () {
      if (this.payload==null)
        return false;
      if ('pinned' in this.payload)
        return this.payload.pinned;
      return false;
    }

  },

  methods: {

    isPin(pin)
    {
      return pin!=""
    },
    updatePin()
    {
      if (this.showAuthPin)
        return;
      if (this.isPin(this.pin)!==false)
      {
        this.showAuthPin=true;
      }
    },
    pinAuth() {
      this.showAuthPin=false;
      this.reloadUser();
    },
    pinFail() {

      this.showAuthPin=false;
    },
    updateMSP() {
      const data={
        msp:this.codiceMSP
      };
      apiAxios.post("/user/verifyMSP",data)
        .then(response=>{
          const data =response.data.VerifyMSP;
          if (data===true)
          {
            this.reloadUser();
          }
        })
        .catch(error=>{
          console.log(error);
        })
    },
    updateCodAuth() {
      const data={
        authcode:this.codAuth
      };
      apiAxios.post("/user/verifyAuth",data)
          .then(response=>{
            const data =response.data.VerifyAuthResult;
            if (data===true)
            {
              this.reloadUser();
            }
          })
          .catch(error=>{
            console.log(error);
          })
    },
    emailAuth() {
      this.showAuthEmail=false;
    },
    emailFail() {
      this.showAuthEmail=false;
    },
    updateEmail() {
      if (this.showAuthEmail)
        return;
      if (this.$isEmail(this.userData.email)!==false)
      {
        this.showAuthEmail=true;
      }
    },
    smsAuth() {
      this.showAuthSms=false;
    },
    smsFail() {
      this.showAuthSms=false;
    },
    updateSms() {
      if (this.showAuthSms)
        return;
      if (this.$isPhone(this.userData.cellulare)!==false)
      {
        this.showAuthSms=true;
      }
    },
    async reloadUser()
    {
      await store.dispatch("dateUser/loadUser").then(() => {
        console.log("Userdata OK:"+JSON.stringify(store.getters['dateUser/UserData']));

      });
    },
    async getUser() {
      try {
        await this.$store.dispatch("dateUser/getUser").then(() => {
          this.payload = this.$store.getters["dateUser/UserData"];
          console.log(this.payload);
          return this.payload;
        });
      } catch (e) {
        console.log({ message: "Some error getUser" });
      }
    }},
};
</script>
