<template>
  <div class="row no-gutters" v-if="payload!=null">
    <div class="col-xl-10 offset-xl-1">

      <h3 class="text-uppercase mt-3">
        <strong>{{ $t("fat_ref.title") }} </strong>
      </h3>
      <h3 class=" mt-3">
        {{ $t("fat_ref.sub_title") }}
      </h3>
      <div v-if="false" class="m-bunner-strong">
        <div class="m-bunner-strong__container">
          <div class="m-bunner-strong__line"></div>
          <span class="icon-lock"></span>
          <div class="m-bunner-strong__line"></div>
        </div>
        <p class="text-center">
          {{ $t("registration.credentials.message") }}
          <b-button class=" mb-1" variant="warning" size="sm" @click="reloadUser()">Ricarica</b-button>
        </p>
      </div>

      <b-alert :show="showAlert" variant="warning">{{ $t('registration.credentials.alert_cell_email') }}</b-alert>

      <div class="m-card m-card--editdata mt-3">
        <div class="m-card__form">
          <b-form-group
              id="codiceMSP"
              :label="(this.binded ? this.$t('generic.codMSP') : this.$t('registration.credentials.codiceMSP_placeholder'))"
              label-for="codiceMSP-input"
              class="m-input m-input__password"
          >
            <div
                class="icon-eye mr-3 m-input__password"
                @mousedown="changeTypeInput('codiceMSP-input')"
            ></div>

            <b-form-input
                id="codiceMSP-input"
                v-model="codiceMSP"
                :placeholder="this.$t('registration.credentials.codiceMSP_placeholder')"
                required
                :type="(this.binded ? 'password' : 'text')"
                :disabled="this.binded"
            ></b-form-input>

            <div v-if="this.binded" >
              <p class="text-success">
                <strong>{{ $t("registration.credentials.validated") }}</strong>
              </p>
            </div>
          </b-form-group>
        </div>

        <div class="m-card__action" v-if="!this.binded">
          <b-button @click="updateMSP()" class=" mb-1" variant="primary" size="md" :disabled="codiceMSP===''">{{
              $t("generic.invia")
            }}</b-button>
        </div>
      </div>
      
      <div v-if="validated && validation>1" class="m-card m-card--editdata mt-3">
        <div class="m-card__form">
          <b-form-group
              id="codAuth"
              :label="(this.authenticated ? this.$t('generic.codAuth') : this.$t('registration.credentials.codAuth_placeholder'))"
              label-for="codauth-input"
              class="m-input m-input__password"
          >
            <div
                class="icon-eye mr-3 m-input__password"
                @mousedown="changeTypeInput('codauth-input')"
            ></div>

            <b-form-input
                id="codauth-input"
                v-model="codAuth"
                :placeholder="this.$t('registration.credentials.codAuth_placeholder')"
                required
                :type="(this.authenticated ? 'password' : 'text')"
                :disabled="this.authenticated"
            ></b-form-input>

            <div v-if="this.authenticated">
              <label class="text-success ">
                <strong>{{ $t("registration.credentials.authenticated") }}</strong>
              </label>
            </div>
          </b-form-group>
        </div>

        <div class="m-card__action" v-if="this.authenticable">
          <b-button @click="updateCodAuth()" class=" mb-1" variant="primary" size="md" :disabled="codAuth===''">{{
              $t("generic.invia")
            }}</b-button>
        </div>
      </div>

      <div  v-if="false" class="m-card m-card--editdata mt-3">
        <div class="m-card__form">
          <b-form-group
              id="pin"
              :label="this.$t('registration.credentials.pin')"
              label-for="pin-input"
              class="m-input m-input__icon"
          >
            <div class="icon-phone-check mr-3"></div>
            <div v-if="this.pinned" >

              <p class="text-success">
                <strong>{{ $t("registration.credentials.pinned") }}</strong>
              </p>
            </div>
            <b-form-input v-if="!this.pinned"
                          id="pin-input"
                          v-model="pin"
                          :placeholder="this.$t('registration.pin_placeholder')"
                          required
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="m-card__action" v-if="!this.pinned">
          <b-button @click="updatePin()" class=" mb-1" variant="primary" size="md">{{
              $t("generic.invia")
            }}</b-button>
        </div>
        <app-auth-panel v-if="showAuthPin" action="setPin" :value="pin" media="sms" :message="this.$t('dettagli.pin_sms_message')" @success="pinAuth()" @fail="pinFail()"/>
      </div>
      <div
      ><br><br><strong>{{ $t("fat_ref.footer") }}</strong>  <b-button @click="gotoHome()" class=" mb-1" variant="primary" size="md" >HOME</b-button></div
      >
    </div>
    <b-modal ref="query_password"
             id="query_password"
             centered
             modal-class="o-modal"
             content-class="o-modal__content"
             header-class="o-modal__header"
             dialog-class="o-modal__dialog"
             body-class="o-modal__body o-modal__body--response "
             ok-title="ok-title"
             :cancel-disabled="false"
             :hide-footer="true"
             :hide-header-close="true"
             :hide-header="true"
             size="lg"
    >
      <div class="o-modal__body--icon-close">
        <span class="icon-lock"></span>
      </div>
      <p class="o-modal__body--title text-warning">
        <strong> Richiesta Codice Autenticazione</strong>
      </p>
      <p>
        <strong>Inserire password di login, per autorizzare recupero Codice Autenticazione</strong>

      </p>


      <p class="text-danger" v-if="errPass!=null">
        {{errPass}}
      </p>

        <div class="m-card__form">
          <b-form-group
              id="pin"
              :label="Password"
              label-for="pin-input"
              class="m-input m-input__icon"
          >
            <div class="icon-lock mr-3"></div>

            <b-form-input
                          id="pass-input"
                          v-model="password"
                          placeholder="Password"
                          required
                          type="password"
            ></b-form-input>
          </b-form-group>
        </div>
      <b-button v-if="!jit" variant="primary" size="" class="text-light text-uppercase mt-3"  @click="queryAuthCode(password)"
      >INVIA</b-button
      >
      <b-button variant="outline-primary" size="" class="text-uppercase mt-3"  @click="$refs['query_password'].hide()"
      >ANNULLA</b-button
      >


    </b-modal>


    <b-modal ref="cod-fail"
             id="cod-fail"
             centered
             modal-class="o-modal"
             content-class="o-modal__content"
             header-class="o-modal__header"
             dialog-class="o-modal__dialog"
             body-class="o-modal__body o-modal__body--response "
             ok-title="ok-title"
             :cancel-disabled="true"
             :hide-footer="true"
             :hide-header-close="true"
             :hide-header="true"
             size="lg"
    >
      <div class="o-modal__body--icon-close">
        <span class="icon-eye"></span>
        <span  class="icon-close text-light"></span>
      </div>
      <p class="o-modal__body--title text-danger">
        <strong >Errore Codice Autenticazione</strong>
      </p>
      <p>
        Il Codice inserito non è corretto.
      </p>
      <b-button variant="primary" size="" class="text-light text-uppercase mt-3" @click="closeMessaggioCod()"
      >OK</b-button
      >
    </b-modal>
  </div>
</template>

<script>
import AppAuthPanel from "@/components/organisms/AppAuthPanel";
import apiAxios from "@/libs/apiAxios";
import store from "@/store";
import router from "@/router";
export default {
  name: "AppFatRefInformation",
  components: {AppAuthPanel},
  props: ["userData"],

  created(){
    if(this.binded) this.codiceMSP = this.payload.codMSP;
    if(this.authenticated) this.codAuth = this.payload.codAuth;
  },
  data() {
    return {
      codiceMSP:'',
      codAuth:'',
      pin:'',
      password:'',
      showAuthEmail:false,
      showAuthSms:false,
      showAuthPin:false,
      errPass:null,
      newData: {},
    };
  },
  computed: {
    payload: function (){
      const user=this.$store.getters["dateUser/UserData"];
      console.log(user);
      return user;
    },
    /*strong: function () {
      if (this.payload==null)
        return false;
      if (!('role' in this.payload))
        return false;
      return this.payload.role===3;
    },*/
    binded: function () {
      if (this.payload==null)
        return false;
      if ('bind' in this.payload)
        return this.payload.bind==1;
      return false;
    },
    validation: function () {
      if (this.payload==null)
        return 0;
      if ('validation' in this.payload)
        return this.payload.validation;
      return 0;
    },
    validated: function () {
      if (this.payload==null)
        return false;
      if ('validation' in this.payload)
        return this.payload.validated>0;
      return false;
    },
    authenticated: function () {
      if (this.payload==null)
        return false;
      if ('authenticated' in this.payload)
        return this.payload.authenticated;
      return false;
    },
    authenticable: function () {
      if (this.payload==null)
        return false;
      if ('authenticable' in this.payload)
        return this.payload.authenticable;
      return false;
    },
    pinned: function () {
      if (this.payload==null)
        return false;
      if ('pinned' in this.payload)
        return this.payload.pinned;
      return false;
    },
    showAlert: function() {
      return (this.payload.cell==='' || this.payload.cell===null || this.payload.email==='' || this.payload.email===null);
    }
  },

  methods: {
    closeMessaggioCod()
    {
      this.$refs['cod-fail'].hide();
    },
    isPin(pin)
    {
      return pin!=""
    },
    updatePin()
    {
      if (this.showAuthPin)
        return;
      if (this.isPin(this.pin)!==false)
      {
        this.showAuthPin=true;
      }
    },
    pinAuth() {
      this.showAuthPin=false;
      this.reloadUser();
    },
    pinFail() {
      this.showAuthPin=false;
    },
    updateMSP() {
      const data={
        msp:this.codiceMSP
      };
      apiAxios.post("/user/verifyMSP",data)
        .then(response=>{
          const data =response.data.VerifyMSP;
          if (data===true)
          {
            this.reloadUser();
          }
        })
        .catch(error=>{
          console.log(error);
        })
    },
    updateCodAuth() {
      const data={
        authcode:this.codAuth
      };
      apiAxios.post("/user/verifyAuth",data)
          .then(response=>{
            const data =response.data.VerifyAuthResult;
            if (data===true)
            {
              this.reloadUser();
            }
            else {
              this.$refs['cod-fail'].show();
            }
          })
          .catch(error=>{
            console.log(error);
            this.$refs['cod-fail'].show();
          })
    },
    emailAuth() {
      this.showAuthEmail=false;
    },
    emailFail() {
      this.showAuthEmail=false;
    },
    updateEmail() {
      if (this.showAuthEmail)
        return;
      if (this.$isEmail(this.userData.email)!==false)
      {
        this.showAuthEmail=true;
      }
    },
    gotoHome(){
      router.push("/");
    },
    smsAuth() {
      this.showAuthSms=false;
    },
    smsFail() {
      this.showAuthSms=false;
    },
    updateSms() {
      if (this.showAuthSms)
        return;
      if (this.$isPhone(this.userData.cellulare)!==false)
      {
        this.showAuthSms=true;
      }
    },
    async reloadUser()
    {
      await store.dispatch("dateUser/loadUser").then(() => {
        console.log("Userdata OK:"+JSON.stringify(store.getters['dateUser/UserData']));

      });
    },
    async getUser() {
      try {
        await this.$store.dispatch("dateUser/getUser").then(() => {
          this.payload = this.$store.getters["dateUser/UserData"];
          console.log(this.payload);
          return this.payload;
        });
      } catch (e) {
        console.log({ message: "Some error getUser" });
      }
    },
    async queryAuthCode(password){
      const data={
        pass:password
      }
      const _this=this;
      apiAxios.post("user/queryAuth",data)
          .then(response=>{
            const data =response.data.QueryAuthResult;
            if (data.success)
            {
              _this.codAuth=data.data;
              document.getElementById('codauth-input').type = "text";
              _this.$refs['query_password'].hide()
            }
            else {

              _this.errPass="Password non valida"
            }
          })
          .catch(error=>{
            console.log(error);
            _this.errPass="Errore: "+error
          })
    },
    changeTypeInput(id) {
      if (this.codAuth==='@@@@@@@@' && id==='codauth-input')
      {
        this.errPass=null;
        this.password='';
        this.$refs['query_password'].show();
        return;
      }
      if (document.getElementById(id).type !== "text") {
        document.getElementById(id).type = "text";
      } else {
        document.getElementById(id).type = "password";
      }
    }
  },
};
</script>
