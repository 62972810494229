<template>
  <div class="">
    
  </div>
</template>

<script>
export default {
  name: "AppOccupationalMedicine",
  props: ["userData"],

  data() {
    return {};
  },
  computed: {},

  methods: {},
};
</script>
