<template>
  <div class="">
    <p v-if="false">{{ userData }}</p>

    <div class="row no-gutters">
      <div class="col-xl-10 offset-xl-1">
        <h3 class="text-uppercase mb-3">
          <strong>{{ $t("mdl.consensi.privacy_title") }} </strong>
        </h3>

        <div class="">


          <p><small><strong>{{ $t("mdl.consensi.privacy_1") }}</strong></small></p>

          <div v-for="azienda in this.consensi" v-bind:key="azienda.nome_azienda" class="m-3 text-left">
            <p>{{$t("mdl.consensi.azienda")}} {{azienda.nome_azienda}}</p>
            <AppMdlConsenso v-for="cons in azienda.consensi" v-bind:key="cons.tipo_consenso" :item="cons"></AppMdlConsenso>
            <hr>
          </div>
          <h3 v-if="this.consensi.length==0">
            {{$t("mdl.consensi.no-consensi")}}
          </h3>

          <div class="mt-3">
            {{$t("mdl.consensi.download")}}<strong><u><a :href="pdfLink"  target="_blank" downlaod="InformativaPrivacyMDLPaziente">{{$t("mdl.consensi.download_link")}}</a></u></strong>
          </div>



          <b-alert v-model="setMsg" :variant="setMsgType">
            {{ (this.setMsgResult ? $t('privacy.alert_ok') : $t('privacy.alert_ko')) }}
          </b-alert>
          <div class="row">
            <div class="o-registration-collapse__error" v-if="privacyError!=null">{{
                $t("mdl.consensi.errors."+this.privacyError)
              }}</div>
          </div>
          <div class="text-center mt-3">
            <b-button
                @click="inviaConsensi"
                variant="primary"
                size="md"
            >{{ $t("generic.update") }}
            </b-button
            >
          </div>

        </div>
      </div>

    </div>

    <app-confirm :showModal="showModal" @confirm="saveConsensi()" @cancel="abort()"></app-confirm>


  </div>
</template>

<script>

import apiAxios from "@/libs/apiAxios";
import AppConfirm from './AppConfirm.vue';
import AppMdlConsenso from "@/components/molecules/AppMdlConsenso";

export default {
  components: {AppConfirm,AppMdlConsenso},
  name: "AppPrivacyMdl",
  props: ["userData"],

  data() {
    return {
      consensi:[],
      privacyError:null,
      pdfLink: require("@/assets/docs/Informativa_MDL_pazienti.pdf"),
      setMsg: false,
      setMsgType: null,
      setMsgResult: null,
      showModal: false
    };
  },

  computed: {},

  created() {
    sessionStorage.removeItem('mdl_consensi_key');
    this.checkConsensi();
  },

  methods: {
    showConfirm() {
      this.showModal = true;
      this.setMsg = false;
    },
    checkConsensi() {

      this.consensi = [];
      const _this = this;
      apiAxios.get('mdl/consensi').then(result => {
            const data = result.data.MdlConsensiResult;

            if (data.success) {
              _this.showConsensi = false;
              for (let i = 0; i < data.consensi.data.length; i++) {
                const az = data.consensi.data[i];
                let azienda = {
                  nome_azienda: az.azienda_consenso,
                  codice_azienda_consenso:az.codice_azienda_consenso,
                  id: 'AZ' + i,
                  consensi: []
                };
                for (let c = 0; c < az.consenso_credenziali_miocdi.length; c++) {
                  let cons = az.consenso_credenziali_miocdi[c];
                  cons.id = 'AZ' + i + '-CON' + c;
                  azienda.consensi.push(cons);
                }


                _this.consensi.push(azienda);
              }


            } else {
              _this.consensi = [];
            }

          }
      )
          .catch(error => {
            console.log(error);
            _this.consensi = [];
          })
    },
    abort() {
      this.showModal = false;
    },

    save() {
      this.showModal = false;

      const data = {
        consensi: [this.userData.cons_1,
          this.userData.cons_2,
          this.userData.cons_3,
          this.userData.cons_4,
          this.userData.cons_5,
          this.userData.cons_6,
          this.userData.cons_7,
          false,
          false,
          false]
      }

      apiAxios.post("/user/setConsensi", data)
          .then(response => {
            const resp = response.data;
            if (resp.SetConsensiResult) {
              this.setMsg = true;
              this.setMsgType = 'success';
              this.setMsgResult = true;
            } else {
              this.setMsg = true;
              this.setMsgType = 'danger';
              this.setMsgResult = false;
            }
          })
    },
    inviaConsensi() {
      let go=true;
      for (var a=0;a<this.consensi.length;a++)
      {
        const azienda=this.consensi[a];
        for (var c=0;c<azienda.consensi.length;c++)
        {
          if (azienda.consensi[c].consenso===null)
          {
            go=false;
            break;
          }
        }
      }

      if (go) {
        this.showModal = true;
      }
      else {
        this.privacyError="MISSING_CONSENSO";
      }
    },
    saveConsensi(){


      this.privacyError=null;
        const _this=this;
        let data=this.consensi;
        apiAxios.post('/mdl/consensi',data).then(response=>{
          const data=response.data.MdlConsensiResult;

          if (data.success)
          {
            this.showModal=false;
            this.setMsg = true;
            this.setMsgType = 'success';
            this.setMsgResult = true;
          }
          else {
            _this.privacyError="MISSING_CONSENSO";
            this.showModal=false;
          }
        })

    }
  },
};
</script>
