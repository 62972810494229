<template>
  <div class="registrazione">
    <div class="row no-gutters">
      <div class="col-lg-10 offset-lg-1">
        <app-user-details v-if="user!=null"
         :userData="user"

        ></app-user-details>
      </div>
    </div>
  </div>
</template>
<script>



import apiAxios from "@/libs/apiAxios";
import AppUserDetails from "@/components/organisms/AppUserDetails";
export default {
  components: {AppUserDetails  },
  name: "dettagliUtenza",

  data() {
    return {
      registration: {
        firstName: "Giacomo",
        lastName: "Magnani",
        codiceFiscale: "mgngcm90c29c618x",
        straniero: false,
        birthDate: "1990-03-29T00:00:00+00:00",
        gender: "M",
        country: "Brescia",
        nationBorn: "Italia",
        cityBornFromTaxCode: "CHIARI",
        username: "mgngcm90c29c618x",
        email: "jack.magnakoki@gmail.com",
        tel: "3293978528",
        invoice: {
          data: {
            administrative: "Lombardia",
            city: "Palazzolo sull'Oglio",
            country: "Italia",
            countryCode: "it",
            county: "Brescia",
            name: "Via Luigi Pirandello 27",
            postcode: "25036",
            value:
                "Via Luigi Pirandello 27, Palazzolo sull'Oglio, Lombardia, Italia",
          },
        },
      },

      user: null
    };
  },
  created() {
    this.loading=true;
      this.userdata=null;
      this.getUserData();

    },
    methods: {

       async getUserData() {
           this.loading = true
           apiAxios.get('/user/detail')
               .then(response => {
                 this.loading = false
                 this.user = response.data.UserDetailResult;

                 console.log(this.user);


               })
               .catch(error => {
                 this.loading = false
                 console.log(error)
               })

        },
    }
};
</script>
