<template>
  <div class="row no-gutters">
    <div class="d-block d-lg-none">
      <button class="o-userdetails__toggle is-close" @click="toggle">
        <span> <span></span><span></span><span></span> </span>
      </button>
      <div class="o-userdetails__nav ">
        <div vertical class="o-userdetails__nav--link mt-5">
          <h3 class="mb-2">
            <strong>{{ $t("userDetalies.section") }}</strong>
          </h3>
          <a
            @click="tabIndex = 0"
            v-bind:class="{ 'font-weight-bold': tabIndex === 0 }"
            >{{ $t("personalData.link") }}</a
          >
          <a
            @click="tabIndex = 1"
            v-bind:class="{ 'font-weight-bold': tabIndex === 1 }"
            >{{ $t("loginCredetials.link") }}</a
          >
          <a
            @click="tabIndex = 2"
            v-bind:class="{ 'font-weight-bold': tabIndex === 2 }"
            >{{ $t("billingInformation.link") }}</a
          >
          <a v-if="false"
            @click="tabIndex = 3"
            v-bind:class="{ 'font-weight-bold': tabIndex === 3 }"
            >{{ $t("familyInformation.link") }}</a
          >
          <a v-if="false"
            @click="tabIndex = 4"
            v-bind:class="{ 'font-weight-bold': tabIndex === 4 }"
            >{{ $t("occupationMedicinine.link") }}</a
          >
          <a
            @click="tabIndex = 5"
            v-bind:class="{ 'font-weight-bold': tabIndex === 5 }"
            >{{ $t("privacy.link") }}</a
          >

          <a
              @click="tabIndex = 6"
              v-if="hasMdl"
              v-bind:class="{ 'font-weight-bold': tabIndex === 6 }"
          >{{ $t("mdl.consensi.privacy_link") }}</a
          >
          <a
            @click="tabIndex = 7"
            v-bind:class="{ 'font-weight-bold': tabIndex === 7 }"
            >{{ $t("fat_ref.link") }}</a
          >                    
        </div>
        <div class="o-userdetails__nav--link mt-4 mt-xl-0 ">
          <a @click="logout"
            ><strong>{{ $t("userDetalies.exsternal_link_0") }}</strong></a
          >
          <a v-if="false" href=""
            ><strong>{{ $t("userDetalies.exsternal_link_1") }}</strong></a
          >
          <a v-if="false" href=""
            ><strong>{{ $t("userDetalies.exsternal_link_2") }}</strong></a
          >        
        </div>
      </div>
    </div>
    <div class="col-10 offset-1 col-lg-12 offset-lg-0">
      <div class="m-card text-center">
        <h1 class="o-heading o-heading--xl">
          {{ $t("userDetalies.welcome") }} {{ userData.nome }} {{ userData.cognome }}
        </h1>
      </div>
    </div>
    <div class="col-12 offset-0 col-xl-10 offset-xl-1 mt-4">
      <div class="o-userdetails">
        <div class="o-userdetails__nav ">
          <div vertical class="o-userdetails__nav--link">
            <a
              @click="tabIndex = 0"
              v-bind:class="{ 'font-weight-bold': tabIndex === 0 }"
              >{{ $t("personalData.link") }}</a
            >
            <a
              @click="tabIndex = 1"
              v-bind:class="{ 'font-weight-bold': tabIndex === 1 }"
              >{{ $t("loginCredetials.link") }}</a
            >
            <a
              @click="tabIndex = 2"
              v-bind:class="{ 'font-weight-bold': tabIndex === 2 }"
              >{{ $t("billingInformation.link") }}</a
            >
            <a v-if="false"
              @click="tabIndex = 3"
              v-bind:class="{ 'font-weight-bold': tabIndex === 3 }"
              >{{ $t("familyInformation.link") }}</a
            >
            <a v-if="false"
              @click="tabIndex = 4"
              v-bind:class="{ 'font-weight-bold': tabIndex === 4 }"
              >{{ $t("occupationMedicinine.link") }}</a
            >
            <a
              @click="tabIndex = 5"
              v-bind:class="{ 'font-weight-bold': tabIndex === 5 }"
              >{{ $t("privacy.link") }}</a
            >
            <a
                @click="tabIndex = 6"
                v-bind:class="{ 'font-weight-bold': tabIndex === 6,'  o-userdetails__hidden {':!hasMdl }"
            >{{ $t("mdl.consensi.privacy_link") }}</a
            >
            <a
              @click="tabIndex = 7"
              v-bind:class="{ 'font-weight-bold': tabIndex === 7 }"
              >{{ $t("fat_ref.link") }}</a
            >                  
          </div>
          <div class="o-userdetails__nav--link mt-4 mt-xl-0 ">
            <a @click="logout"
              ><strong>{{ $t("userDetalies.exsternal_link_0") }}</strong></a
            >
          </div>
        </div>

        <b-tabs pills class="o-userdetails__tabs" v-model="tabIndex">
          <b-tab>
            <app-personal-data :userData="userData"></app-personal-data>
          </b-tab>

          <b-tab>
            <app-login-credetials :userData="userData"></app-login-credetials>
          </b-tab>

          <b-tab>
            <app-billing-information
              :userData="registration"
            ></app-billing-information>
          </b-tab>

          <b-tab>
            <app-family-information
              :userData="userData"
            ></app-family-information>
          </b-tab>

          <b-tab>
            <app-occupational-medicine
              :userData="userData"
            ></app-occupational-medicine>
          </b-tab>

          <b-tab>
            <app-privacy
              :userData="userData"
            ></app-privacy>
          </b-tab>

          <b-tab>
            <app-privacy-mdl
                :userData="userData"
            ></app-privacy-mdl>
          </b-tab>

          <b-tab>
            <app-fat-ref-information
              :userData="userData"
            ></app-fat-ref-information>
          </b-tab>               

          <!--  <b-tab>
        <b-card-text>DISCONNETTI</b-card-text>
      </b-tab> -->
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import AppBillingInformation from "./AppBillingInformation.vue";
import AppFamilyInformation from "./AppFamilyInformation.vue";
import AppLoginCredetials from "./AppLoginCredetials.vue";
import AppOccupationalMedicine from "./AppOccupationalMedicine.vue";
import AppPrivacy from "./AppPrivacy.vue";
import AppPrivacyMdl from "./AppPrivacyMdl.vue";
import AppPersonalData from "./AppPersonalData.vue";
import AppFatRefInformation from "./AppFatRefInformation.vue";
export default {
  components: {
    AppPersonalData,
    AppLoginCredetials,
    AppBillingInformation,
    AppFamilyInformation,
    AppOccupationalMedicine,
    AppPrivacy,
    AppFatRefInformation,
    AppPrivacyMdl
  },
  name: "AppUserDetails",
  props: ["userData"],

  data() {
    let tabI;
    try {
      tabI = parseInt(this.$route.query.tab !== undefined ? this.$route.query.tab : 0);
    } catch(e) {
      tabI = 0;
    }
    return {      
      tabIndex: tabI,
        registration: {
        firstName: "Giacomo",
        lastName: "Magnani",
        codiceFiscale: "mgngcm90c29c618x",
        straniero: false,
        birthDate: "1990-03-29T00:00:00+00:00",
        gender: "M",
        country: "Brescia",
        nationBorn: "Italia",
        cityBornFromTaxCode: "CHIARI",
        username: "mgngcm90c29c618x",
        email: "jack.magnakoki@gmail.com",
        tel: "3293978528",
        invoice: {
          data: {
            administrative: "Lombardia",
            city: "Palazzolo sull'Oglio",
            country: "Italia",
            countryCode: "it",
            county: "Brescia",
            name: "Via Luigi Pirandello 27",
            postcode: "25036",
            value:
              "Via Luigi Pirandello 27, Palazzolo sull'Oglio, Lombardia, Italia",
          },
        },
      },
    };
  },
  computed: {
    $menu() {
      return this.$el.querySelector(".o-userdetails__nav");
    },
    hasMdl(){
      if ('services' in this.userData)
      {
        for (let i =0;i<this.userData.services.length;i++)
        {
          if (this.userData.services[i]==='MDL')
          {
            return true;
          }
        }
      }
      return false;
    }
  },

  methods: {
    toggle(e) {
      const $clicked = e.currentTarget;
      /* const $wrapper = $clicked.parentNode; */
      console.log(this.$menu.classList);
      if ($clicked.classList.contains("is-close")) {
        this.$menu.classList.add("is-visible");
        $clicked.classList.remove("is-close");
      } else {
        $clicked.classList.add("is-close");
        this.$menu.classList.remove("is-visible");
      }

      /*  for (let i = 0; i < this.$inners.length; i++) {
        this.$inners[i].scroll(0, 0);
      }

      if ($wrapper.classList.contains("is-toggle")) {
        $wrapper.classList.remove("is-toggle");
      } else {
        $wrapper.classList.add("is-toggle");
      } */
    },

    async logout(event) {
      event.preventDefault();
      console.log('logout');
      try {
         await this.$store.dispatch("logout").then(() => {
            this.$store.dispatch("dateUser/resetState");
            this.$router.push({ name: "dashboard" });
        });
      } catch (e) {
        console.log({ message: "Some error login" });
      }
    },
  },
};
</script>
